import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { jwtDecode } from 'jwt-decode';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../../components/ui/dialog';
import { useNavigate } from 'react-router-dom';


interface packets {
    id: number
    name: string
    description: string
    apps: string[]
    price: number
    appsDetail: any
}

function Market() {


    const [packets, setPackets] = React.useState<packets[]>([]);
    const [selectedPackets, setSelectedPackets] = React.useState({} as packets);
    const [selectedMounthly, setSelectedMounthly] = React.useState(0);
    const [selectedAmount, setSelectedAmount] = React.useState(0);
    const navigate = useNavigate();
    const packets2 = [
        {
            mounthly: 1,
            discount: 0,
            index: 0
        },
        {
            mounthly: 3,
            discount: 10,
            index: 1
        },
        {
            mounthly: 6,
            discount: 20,
            index: 2
        },
        {
            mounthly: 12,
            discount: 30,
            index: 3
        },

    ]
    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/package/all?page=1&limit=10')
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    setPackets(data.items);
                } else {
                    //console.log(data)
                }
            }).catch((err) => console.log(err))
    }, [])

    const paymentSend = () => {

        let decoded
        const token = localStorage.getItem('token');


        if (token && token !== null) {
            decoded = jwtDecode(token);
        } else {
            navigate('/login')
        }
        if (decoded) {
            fetch(process.env.REACT_APP_API_URL + '/payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(
                    "userId" in decoded && {
                        "packageId": selectedPackets?.id,
                        "userId": decoded.userId,
                        "duration": 30 * packets2[selectedMounthly].mounthly,
                        "amount": selectedMounthly === 0 ? selectedPackets.price : selectedAmount * packets2[selectedMounthly].mounthly,
                        "comment": ""
                    }

                )

            }).then((res) => res.json()).then((data) => {
                window.location.href = data.url
            }).catch((err) => console.log(err))
        }
    }


    return (

        <div>
            <div className='w-full items-center justify-center flex mb-4 mt-8'>
                <div>
                    <h1 className='text-5xl font-bold'>Paketler</h1>
                </div>
            </div>
            <div className='w-full grid sm:grid-cols-3 grid-cols-1'>
                {
                    packets && packets.map((packet, index) => (
                        <Card className="w-auto h-auto m-4 rounded border-2 border-gray-200 shadow-lg" style={{ borderRadius: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <CardHeader>
                                <CardTitle>
                                    <div className='p-4 flex items-center justify-center h-[80px]'>
                                        <div>
                                            <h2 className='text-4xl'>{packet.name}</h2>
                                        </div>
                                    </div>
                                </CardTitle>
                                <CardDescription>
                                    <div className='block'>
                                        <div className=''>
                                            <img style={{ borderRadius: '10px' }} src="https://i.ytimg.com/vi/iNANTZCeRsk/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLC3QWnTXft1Qw7KghLO5uUz0M2LVA" alt="" />
                                        </div>
                                        <div className='flex items-start justify-start p-4 h-20'>
                                            <div>
                                                <span className='text-xl '> {packet.description}</span>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='mt-[20px]'>
                                            {
                                                packet.appsDetail.map((app: any, index: number) => (
                                                    <div key={index} className='flex items-start justify-start w-[70px]' style={{ borderRadius: '100px', marginLeft: '20px' }}>
                                                        <div>
                                                            {/* <span className='text-xl '>{app.image}</span> */}
                                                            <img src={app.image} alt="denme" style={{ borderRadius: '100px' }} />
                                                            <div className='flex items-center justify-center'>
                                                                <div>
                                                                    <span>{app.type}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className='flex items-start justify-end p-4 '>
                                            <div className='bg-slate-200 p-4 ' style={{ borderRadius: 'px' }}>
                                                <span className='text-xl font-bold'>{packet.price} ₺</span>
                                            </div>
                                        </div>
                                    </div>
                                </CardDescription>
                            </CardHeader>
                            <CardFooter className="flex justify-between mt-auto">
                                <Dialog>
                                    <DialogTrigger asChild>
                                        <Button variant="outline" onClick={() => setSelectedPackets(packet)} className='bg-[#FAEC23] hover:bg-[#e9e5a7] font-bold py-2 px-4 rounded w-full text-black text-xl font-bold' style={{ borderRadius: '10px' }}>Satın Al</Button>
                                    </DialogTrigger>
                                    <DialogContent className="sm:max-w-[625px] bg-white" style={{ borderRadius: '10px' }}>
                                        <DialogHeader>
                                            <DialogTitle className="text-center w-full text-4xl text-gray-500 pt-4">{packet.name}</DialogTitle>
                                            <DialogDescription className='text-center text-xl'>
                                                {packet.description}
                                            </DialogDescription>
                                        </DialogHeader>
                                        <div className="grid gap-4 py-4">
                                            <div className="grid grid-cols-4 items-center gap-4">
                                                <div>
                                                    <span className='text-sm'>Paketteki Yazılımlar:</span>
                                                </div>
                                                {
                                                    packet.apps && (packet.apps.map((app, index) => (
                                                        <div key={index} className='text-center bg-gray-200 p-1' style={{ borderRadius: '10px' }}>
                                                            <span>{app}</span>
                                                        </div>
                                                    )))
                                                }
                                            </div>
                                        </div>
                                        <div className='text-center items-center text-2xl'>
                                            <span>Çok Al Az Öde</span>
                                        </div>
                                        <div className='h-[100px] flex' style={{ borderRadius: '10px' }}>
                                            {
                                                packets2.map((packet2, index) => (
                                                    <div className='flex justify-center items-center border w-1/4'
                                                        style={{
                                                            borderRadius: index === 0 ? '10px 0px 0px 10px' : (index === 3 ? '0px 10px 10px 0px' : '0px'),
                                                            borderColor: index === selectedMounthly ? 'blue' : '',
                                                        }}
                                                        onClick={() => {
                                                            setSelectedMounthly(index)
                                                            setSelectedAmount(packet.price - (packet.price * packet2.discount / 100))
                                                        }}>
                                                        <div>
                                                            <div className='flex justify-end items-center mb-[-9px]'>
                                                                {packet2.discount > 0 ? <span className='text-red-500'>{packet2.discount}%</span> : <span className='text-transparent'>{packet2.discount}%</span>}
                                                            </div>
                                                            <div className='flex justify-center items-center'>
                                                                <span className='font-bold text-blue-500'>{packet.price - (packet.price * packet2.discount / 100)}₺ / Ay</span>
                                                            </div>
                                                            <span>{packet2.mounthly} Ay Peşin Öde</span>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <DialogFooter>
                                            <div className='flex justify-center items-center w-full'>
                                                <Button onClick={() => paymentSend()} className='bg-[#FAEC23] hover:bg-[#e9e5a7] text-black font-bold py-2 px-4 rounded w-full' type="submit" style={{ borderRadius: '10px' }}>Satın Al</Button>
                                            </div>
                                        </DialogFooter>
                                    </DialogContent>
                                </Dialog>
                            </CardFooter>
                        </Card>

                    ))

                }

            </div >
        </div>
    );
}

export default Market;



