import React, { useEffect } from 'react';
import { jwtDecode } from "jwt-decode";
import ReactPlayer from 'react-player';


function VideosArea() {
    // const videoArray = [
    //     {
    //         title: "Amazon'da Bir Ürünün Sattığını Nasıl Anlarız?",
    //         url: "https://youtu.be/pgakI-hSY6M",
    //         image: "https://process.fs.teachablecdn.com/ADNupMnWyR7kCWRvm76Laz/resize=width:705/https://cdn.filestackcontent.com/RcMymBPdTqyoEjW6rGjK"
    //     },
    //     {
    //         title: "Dropshipping Eğitimi 2024 - Dropshipping Nasıl Yapılır?",
    //         url: "https://youtu.be/577uf10Nx_M",
    //         image: "https://i.ytimg.com/vi/577uf10Nx_M/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC5_UhnXdZ1aJVxBDNXDW50N8II6g"
    //     },
    //     {
    //         title: "Video 1",
    //         url: "https://youtu.be/nL5r7-Oj2JQ",
    //         image: "https://i.ytimg.com/vi/nL5r7-Oj2JQ/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDGLY1-uksQUW7PSJAY-HVDrcIaqQ"
    //     },
    //     {
    //         title: "Amazon'da Bir Ürünün Sattığını Nasıl Anlarız?",
    //         url: "https://youtu.be/pgakI-hSY6M",
    //         image: "https://process.fs.teachablecdn.com/ADNupMnWyR7kCWRvm76Laz/resize=width:705/https://cdn.filestackcontent.com/RcMymBPdTqyoEjW6rGjK"
    //     },
    //     {
    //         title: "Dropshipping Eğitimi 2024 - Dropshipping Nasıl Yapılır?",
    //         url: "https://youtu.be/577uf10Nx_M",
    //         image: "https://i.ytimg.com/vi/577uf10Nx_M/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC5_UhnXdZ1aJVxBDNXDW50N8II6g"
    //     },
    //     {
    //         title: "Video 1",
    //         url: "https://youtu.be/nL5r7-Oj2JQ",
    //         image: "https://i.ytimg.com/vi/nL5r7-Oj2JQ/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDGLY1-uksQUW7PSJAY-HVDrcIaqQ"
    //     },
    //     {
    //         title: "Amazon'da Bir Ürünün Sattığını Nasıl Anlarız?",
    //         url: "https://youtu.be/pgakI-hSY6M",
    //         image: "https://process.fs.teachablecdn.com/ADNupMnWyR7kCWRvm76Laz/resize=width:705/https://cdn.filestackcontent.com/RcMymBPdTqyoEjW6rGjK"
    //     },
    //     {
    //         title: "Dropshipping Eğitimi 2024 - Dropshipping Nasıl Yapılır?",
    //         url: "https://youtu.be/577uf10Nx_M",
    //         image: "https://i.ytimg.com/vi/577uf10Nx_M/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC5_UhnXdZ1aJVxBDNXDW50N8II6g"
    //     },
    //     {
    //         title: "Video 1",
    //         url: "https://youtu.be/nL5r7-Oj2JQ",
    //         image: "https://i.ytimg.com/vi/nL5r7-Oj2JQ/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDGLY1-uksQUW7PSJAY-HVDrcIaqQ"
    //     }
    // ]
    const [videoMode, setVideoMode] = React.useState(false);
    const [video, setVideo] = React.useState<any>(null);
    const [packets, setPackets] = React.useState<any[]>([]);
    const [videoArray, setVideoArray] = React.useState<any[]>([]);

    const getMyInfo = () => {
        const token = localStorage.getItem('token');
        if (token && token !== null) {
            const decoded: any = jwtDecode(token);
            (decoded && decoded.appPass) && (
                fetch(process.env.REACT_APP_API_URL + '/user/videos', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                }).then((res) => res.json()).catch((err) => console.log(err)).then((data) => {
                    if (data.length > 0) {
                        setPackets(data);
                    }
                })
            )
        }

    }
    useEffect(() => {
        getMyInfo();
    }, [])

    return (
        <div>
            {
                videoMode ? (
                    <div>
                        <div className='w-full block md:flex flex-col lg:flex-row h-[80vh] p-2 lg:p-5'>
                            <div className='lg:w-3/4 bg-slate-500 mb-2 lg:mb-0 h-full overflow-hidden rounded-lg' style={{ borderRadius: '10px' }} >
                                {
                                    video && video.videoUrl && (<ReactPlayer height="100%" width="100%" controls={true} url={video.videoUrl} style={{ borderRadius: '10px' }} />)
                                }
                            </div>
                            <div className='w-full md:w-1/4 block h-100 overflow-y-auto md:ml-4 border-gray border-2 mt-20 md:mt-0' style={{ borderRadius: '10px' }}>
                                <div className='sticky top-0 bg-white h-[40px] flex items-center justify-center border-b border-gray-300'>
                                    <h2>Eğitimler</h2>
                                </div>
                                <div className='p-2'>
                                    {
                                        videoArray.length > 0 && (
                                            videoArray.map((item) => (
                                                <div onClick={() => setVideo({ ...item })} key={item.url} className='flex items-center mb-2 hover:bg-gray-100 p-2 rounded cursor-pointer'>
                                                    <img src={item.photoUrl} alt={item.title} className='w-42 h-28 mr-2 rounded' />
                                                    <span>{item.title}</span>
                                                </div>
                                            ))
                                        )
                                    }
                                </div>
                            </div>

                        </div>
                        {
                            (video !== null && video) && (
                                <div className='w-full items-center justify-center p-4'>
                                    <div className='flex items-start justify-start'>
                                        <span className='text-4xl font-bold'>{video.title}</span>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                ) : (
                    <div className="w-full flex flex-col items-center justify-center p-4">
                        <div className="flex w-full items-center justify-center mb-8">
                            <h1 className="text-3xl font-bold text-center">Eğitim Serileri</h1>
                        </div>
                        <div className="w-full grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                            {
                                packets.map((item) => (
                                    <div
                                        onClick={() => {
                                            setVideoMode(true)
                                            setVideoArray(item.uploads)
                                            setVideo(item.uploads[0])
                                        }}
                                        key={item.url}
                                        className="flex flex-col w-full h-auto items-start mb-2 bg-white shadow-lg hover:shadow-xl p-4 rounded-lg cursor-pointer transition-all duration-300"
                                    >
                                        <img src={item.image} alt={item.title} className="w-full h-48 object-cover rounded-md mb-4" />
                                        <h1 className="text-xl font-semibold mb-2">{item.appType}</h1>
                                        <span className="text-gray-600">Video İçeriği: {item.uploads.length} video</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )

            }
        </div>
    );
}

export default VideosArea;
