import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '../CheckoutForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51PHwBLKcByRq75YPXtS2o9NzjvavwMsAbMkH5k57fa5lgLWapbmAWSlDqaOJNFV8FWTiFRiI4XZliBHngMbfputq00WWHcUJaD');

export default function App() {


    return (
        <div>
            <span>deneme</span>
            <Elements stripe={stripePromise}>
                <CheckoutForm />
            </Elements>
        </div>
    );
};
