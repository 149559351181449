import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import * as Tabs from '@radix-ui/react-tabs';
import Whatsapp from '../../components/ui/whatsapp';

import { Link, PhoneCallIcon } from 'lucide-react';
import Myinfo from '../../components/pages/myInfo';
import DownloadExtensionButton from '../../components/pages/extansion';
import { Button } from '../../components/ui/button';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import ReactPlayer from 'react-player'
import VideosArea from '../../components/pages/VideosArea';
import Market from '../Market';
import HamburgerMenuIcon from '../../components/layout/hamburger';
import MySlider from '../../components/pages/slider';
import Instagram from '../../instagram';


function Account() {
    const navigate = useNavigate();

    const checkToken = async () => {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refresh_token');
        if (token && refreshToken) {
            const decoded = jwtDecode(token);
            const refreshDecoded = jwtDecode(refreshToken);
            if ((decoded.exp && Date.now() > decoded.exp * 1000) && (refreshDecoded.exp && Date.now() > refreshDecoded.exp * 1000)) {
                localStorage.removeItem('token');
                localStorage.removeItem('refresh_token');
                navigate('/login');
            } else if ((decoded.exp && Date.now() > decoded.exp * 1000) && (refreshDecoded.exp && Date.now() < refreshDecoded.exp * 1000)) {
                refreshTokens();
            }
        } else {
            navigate('/login')
        }
    }

    const refreshTokens = () => {
        const BaseUrl = process.env.REACT_APP_API_URL;
        const data = {
            refresh_token: localStorage.getItem('refresh_token')
        }
        const response = fetch(BaseUrl + '/auth/refresh', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),

        }).then((res) => res.json()).then((data) => {
            if (data.access_token) {
                localStorage.setItem('token', data.access_token);
                localStorage.setItem('refresh_token', data.refresh_token);
            } else {
                localStorage.removeItem('token');
                localStorage.removeItem('refresh_token');
                navigate('/login');
            }
        }).catch((error) => {
            console.error('Error:', error);
        })

    };

    useEffect(() => {
        checkToken()
    }, [])

    const [isOpen, setIsOpen] = useState(false);


    return (
        <Layout title="Takvim">
            <Tabs.Root className="TabsRoot" defaultValue="tab1">
                <div className="relative w-full">
                    <div className="md:hidden flex justify-between items-center bg-black p-2">
                        {/* <span className="text-white font-bold text-xl">Menu</span> */}
                        <button
                            className="text-white"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            {isOpen ? <HamburgerMenuIcon className="w-6 h-6" /> : <HamburgerMenuIcon className="w-6 h-6" color='white' />}
                            {/* {isOpen ? <span className="w-6 h-6" >ÇArpı</span> : <span>Menu</span>} */}
                        </button>
                    </div>
                    <div className={`md:flex ${isOpen ? 'block' : 'hidden'} w-full`}>
                        <Tabs.List className="flex flex-col md:flex-row w-full bg-black p-2" aria-label="Manage your account">
                            <Tabs.Trigger className="TabsTrigger w-full md:w-1/3 px-4 mb-2 md:mb-0" style={{ borderRadius: '10px' }} value="tab1" onClick={() => setIsOpen(false)}>
                                <Button className='bg-black w-full hover:bg-gray-700 text-white font-bold py-2 px-4 rounded' >Bilgilerim</Button>
                            </Tabs.Trigger>
                            <Tabs.Trigger className="TabsTrigger w-full md:w-1/3 px-4 mb-2 md:mb-0" style={{ borderRadius: '10px' }} value="tab2" onClick={() => setIsOpen(false)}>
                                <Button className='bg-black w-full hover:bg-gray-700 text-white font-bold py-2 px-4 rounded'>Eklenti</Button>
                            </Tabs.Trigger>
                            <Tabs.Trigger className="TabsTrigger w-full md:w-1/3 px-4 mb-2 md:mb-0" style={{ borderRadius: '10px' }} value="tab3" onClick={() => setIsOpen(false)}>
                                <Button className='bg-black w-full hover:bg-gray-700 text-white font-bold py-2 px-4 rounded'>İletişim</Button>
                            </Tabs.Trigger>
                            <Tabs.Trigger className="TabsTrigger w-full md:w-1/3 px-4 mb-2 md:mb-0" style={{ borderRadius: '10px' }} value="tab4" onClick={() => setIsOpen(false)}>
                                <Button className='bg-black w-full hover:bg-gray-700 text-white font-bold py-2 px-4 rounded'>Eğitimler</Button>
                            </Tabs.Trigger>
                            <Tabs.Trigger className="TabsTrigger w-full md:w-1/3 px-4 mb-2 md:mb-0" style={{ borderRadius: '10px' }} value="tab5" onClick={() => setIsOpen(false)}>
                                <Button className='bg-black w-full hover:bg-gray-700 text-white font-bold py-2 px-4 rounded'>Market</Button>
                            </Tabs.Trigger>
                        </Tabs.List>
                    </div>
                </div>
                {/* <Tabs.List className="flex w-full bg-black p-2" aria-label="Manage your account">
                    <Tabs.Trigger className="TabsTrigger w-1/3 px-4" style={{ borderRadius: '10px' }} value="tab1">
                        <Button className='bg-black w-full hover:bg-gray-700 text-white font-bold py-2 px-4 rounded'>Bilgilerim</Button>
                    </Tabs.Trigger>
                    <Tabs.Trigger className="TabsTrigger w-1/3 px-4" style={{ borderRadius: '10px' }} value="tab2">
                        <Button className='bg-black w-full hover:bg-gray-700 text-white font-bold py-2 px-4 rounded'>Eklenti</Button>
                    </Tabs.Trigger>
                    <Tabs.Trigger className="TabsTrigger w-1/3 px-4" style={{ borderRadius: '10px' }} value="tab3">
                        <Button className='bg-black w-full hover:bg-gray-700 text-white font-bold py-2 px-4 rounded'>İletişim</Button>
                    </Tabs.Trigger>
                    <Tabs.Trigger className="TabsTrigger w-1/3 px-4" style={{ borderRadius: '10px' }} value="tab4">
                        <Button className='bg-black w-full hover:bg-gray-700 text-white font-bold py-2 px-4 rounded'>Eğitimler</Button>
                    </Tabs.Trigger>
                    <Tabs.Trigger className="TabsTrigger w-1/3 px-4" style={{ borderRadius: '10px' }} value="tab5">
                        <Button className='bg-black w-full hover:bg-gray-700 text-white font-bold py-2 px-4 rounded'>Market</Button>
                    </Tabs.Trigger>
                </Tabs.List> */}
                <Tabs.Content className="p-4 border border-t-0 rounded-b-md" value="tab1">
                    <Myinfo info={{ name: 'Mehmet', surname: 'Kılıç', email: 'H0J2S@example.com', phone: '+905058559434', extansionKey: 'extansionKey' }} />
                </Tabs.Content><Tabs.Content className="p-4 rounded-b-md" value="tab2">
                    <div className='block sm:flex justify-between items-center p-5 rounded-lg bg-gray-100'>
                        <div style={{ flex: 1, textAlign: 'center', padding: '20px' }}>
                            <h2 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '1rem', color: '#333' }}>TÜRKİYE’NİN EN GÜVENİLİR AMAZON ORTAK YAZILIM HİZMETİ</h2>
                            <p style={{ fontSize: '1.25rem', marginBottom: '1.5rem', color: '#555' }}>
                                Jungle Scout, Keepa ve Helium 10 yazılım araçlarının ortak kullanım hizmeti ile Amazon hesabınızın aylık satış analizi, rakip satıcıların analizleri ve en çok satan ürünlerin analiz işlemleri gibi verilere kolayca ulaşabilirsiniz.
                            </p>
                            <DownloadExtensionButton />
                        </div>
                        <div style={{ flex: 1, textAlign: 'center', padding: '20px' }}>
                            {/* <video width="100%" height="auto" controls style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <source src="https://youtu.be/XHOmBV4js_E" />
                                Tarayıcınız video etiketini desteklemiyor.
                            </video> */}
                            <iframe width="100%" src="https://www.youtube.com/embed/bFMvtl9foXg?si=SQ79t1oQsGTnEWS9" style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', minHeight: "50vh" }} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                        </div>
                    </div>
                </Tabs.Content>


                <Tabs.Content className="p-4 border border-t-0 rounded-b-md" value="tab3">

                    <div className="container mx-auto px-4 lg:px-0">
                        <h1 className="text-3xl font-bold mb-4">İletişim</h1>
                        <div className="flex flex-col lg:flex-row">
                            <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d24080.144416699193!2d28.806466000000004!3d41.024861!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa3650ae784b7%3A0x4d9eb3b8e1685202!2sMZN%20Dan%C4%B1%C5%9Fmanl%C4%B1k%20E-Ticaret%20ve%20Arge%20Limited%20Teknoloji%20%C5%9Eirketi!5e0!3m2!1sen!2str!4v1717182001768!5m2!1sen!2str" width="100%" height="450" loading="lazy"></iframe>
                            </div>
                            <div className="w-full lg:w-1/2 p-0 md:px-4">
                                <div className="bg-gray-100 rounded-lg p-4">
                                    <h2 className="text-lg font-semibold mb-2">Adres</h2>
                                    <p>Inönü, Merve İş Merkezi, Muammer Aksoy Cd. No:105 Kat:3</p>
                                    <p>34295 Küçükçekmece/İstanbul</p>
                                    <h2 className="text-lg font-semibold mt-4 mb-2">Telefon</h2>
                                    <p>+90 546 598 78 22 (sadece whatsapp destek) </p>
                                    <h2 className="text-lg font-semibold mt-4 mb-2">Telefon</h2>
                                    <p>+90 505 796 44 34</p>
                                    <h2 className="text-lg font-semibold mt-4 mb-2">E-posta</h2>
                                    <p>saityasardev@gmail.com</p>
                                    <a href="http://www.mzndanismanlik.com" className="text-lg font-semibold mt-4 mb-2 text-blue-500" target="_blank" rel="noopener noreferrer" >Mzn Danışmanlık.com</a>

                                </div>
                                <div className="flex flex-wrap w-full space-y-2 lg:space-y-0 lg:space-x-2 mt-2 ">
                                    <button
                                        className="bg-green-500 hover:bg-blue-700 lg:mt-0 text-white font-bold py-2 px-4 rounded flex items-center w-full lg:w-auto justify-center"
                                        onClick={() => window.open('https://api.whatsapp.com/send?phone=%2B905058559434', '_blank')}
                                    >
                                        <Whatsapp className="mr-2" />
                                        Whatsapp Hattımız
                                    </button>
                                    <button
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center w-full lg:w-auto justify-center"
                                        onClick={() => window.open('https://whatsapp.com/channel/0029Va6hO1y1NCrLv95bBU1c', '_blank')}
                                    >
                                        <Whatsapp className="mr-2" />
                                        Whatsapp Grubumuz
                                    </button>
                                    <button
                                        className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center w-full lg:w-auto justify-center"
                                    >
                                        <PhoneCallIcon className="mr-2" />
                                        <a href="tel:+905057424734">Bizi Arayın</a>
                                    </button>
                                    <Button
                                        className="bg-gradient-to-r from-red-500 to-blue-700 hover:from-blue-700 hover:to-red-500 text-white font-bold py-2 px-6 rounded flex items-center w-full lg:w-auto justify-center"
                                        style={{ marginLeft: '0px', marginTop: '10px' }}
                                    >
                                        <Instagram className="mr-2 h-8 w-8" />
                                        <a href="tel:+905057424734">Mzn Danışmanlık</a>
                                    </Button>


                                </div>

                            </div>
                        </div>
                        <div className='my-12'>
                            <MySlider />
                        </div>
                    </div>


                </Tabs.Content>
                <Tabs.Content className="p-4 border border-t-0 rounded-b-md" value="tab4">
                    <VideosArea />
                </Tabs.Content>
                <Tabs.Content className="p-4 border border-t-0 rounded-b-md" value="tab5">
                    <Market />
                </Tabs.Content>
            </Tabs.Root>
        </Layout >
    );
}

export default Account;
