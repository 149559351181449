import Header from '../headers';
import NavigationBar from '../navigationBar.tsx';

function Layout({ children, title }: any) {
    return (
        <div className='block w-full'>
            <Header title={title} />
            <main className='w-full'>{children}</main>
        </div>
    );
}

export default Layout;
