import React from 'react';
import Whatsapp from '../../components/ui/whatsapp';

const StickyButtons = () => {
    return (
        <div className="fixed bottom-10 right-8 flex space-x-2 z-40">
            <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold rounded-full p-4 flex items-center shadow-lg"
                onClick={() => window.open('https://api.whatsapp.com/send?phone=%2B905058559434', '_blank')}
                style={{ boxShadow: '0 0px 10px 0 #25D366' }}
            >
                <Whatsapp />
            </button>
            <button
                className="bg-[#FAEC23] hover:bg-[#e9e5a7] text-white font-bold py-2 px-4 rounded-full flex items-center "
                style={{ boxShadow: '0 0px 10px 0 #FAEC23' }}
            >
                {/* <FaPhone className="mr-2" /> */}
                <a href="tel:+905057424734">Bizi Arayın</a>
            </button>
        </div>
    );
};

export default StickyButtons;
