import React, { useEffect } from 'react';
import { Router, useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { spawn } from 'child_process';

interface MyInfoProps {
    info: {
        name: string;
        surname: string;
        email: string;
        phone: string;
        extansionKey: string;
    };
}

function Myinfo({ info }: MyInfoProps) {
    const [decode, setDecode] = React.useState<any>();
    const [packets, setPackets] = React.useState<any[]>([]);


    const getMyInfo = () => {
        const token = localStorage.getItem('token');
        if (token && token !== null) {
            const decoded: any = jwtDecode(token);

            setDecode(decoded);
            (decoded && decoded.appPass) && (
                fetch(process.env.REACT_APP_API_URL + '/user/apps?app-pass=' + decoded.appPass, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                }).then((res) => res.json()).catch((err) => console.log(err)).then((data) => {
                    if (data.length > 0) {
                        setPackets(data);
                    }
                })
            )
        }

    }
    useEffect(() => {
        getMyInfo();
    }, [])

    return (
        <div className="w-full flex flex-col md:flex-row justify-between bg-white shadow-md rounded-lg p-4">
            <div className="hidden md:block md:w-1/3">
                <img
                    src={`${process.env.PUBLIC_URL}/mznfamily.jpeg`}
                    alt="Profile"
                    className="rounded-lg shadow-md"
                    style={{ borderRadius: '10px' }}
                />
            </div>
            <div className="md:w-2/3 flex justify-between shadow-md rounded-lg p-4 mx-4" style={{ borderRadius: '10px' }}>
                <div className="text-left p-4">
                    <p className="font-semibold mb-4">E-posta:</p>
                    <p className="font-semibold mb-4">Extension Key:</p>
                    <p className="font-semibold mb-4">Paket Bilgisi:</p>
                </div>
                <div className="text-right p-4">
                    <p className="text-gray-700 mb-4">{decode?.email}</p>
                    <div className="flex items-center mb-4">
                        <p className="text-gray-700">{decode?.appPass}</p>
                        <button
                            className="ml-2 px-2 py-1 text-white bg-blue-500 rounded"
                            onClick={() => navigator.clipboard.writeText(decode?.appPass)}
                        >
                            Kopyala
                        </button>
                    </div>
                    <div className="text-gray-700 mt-4 flex">
                        {packets?.map((item, index) => (
                            <div
                                key={index}
                                className="block bg-white ml-4 p-2 shadow-lg border-2"
                                style={{ borderRadius: '10px' }}
                            >
                                <div className="flex align-middle justify-center">
                                    {`${item.appType} `}
                                </div>
                                <div>{`(kalan gün: ${item.remainingDay})`}</div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>


    );
}

export default Myinfo;
