import React, { useState } from 'react';
import { Button } from '../../components/ui/button';
import { Loader } from '../../components/ui/loaders';
import InputMask from 'react-input-mask';
import Layout from '../../components/layout';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate, redirect, useNavigate } from 'react-router-dom';

interface SignInData {
    email: string;
    password: string;
}
interface OtpData {
    otp: string;
}

interface SignUpData {
    email: string;
    phone: string;
    password: string;

}


function Signin() {
    const [formType, setFormType] = useState<'signin' | 'signup' | 'otp'>('signin');
    const [otpData, setOtpData] = useState<OtpData>({ otp: '' });
    const [userId, setUserId] = useState<string>('');
    const [state, setState] = useState({ loading: false, error: '' });
    const [signInData, setSignInData] = useState<SignInData>({ email: '', password: '' });
    const [signUpData, setSignUpData] = useState<SignUpData>({
        email: '',
        phone: '',
        password: '',
    });
    const navigate = useNavigate();




    const handleTabClick = (type: 'signin' | 'signup') => {
        setFormType(type);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, form: 'signin' | 'signup' | 'otp') => {
        const { name, value } = e.target;
        if (form === 'signin') {
            setSignInData({ ...signInData, [name]: value });
        } else if (form === 'signup') {
            setSignUpData({ ...signUpData, [name]: value });
        } else {
            setOtpData({ ...otpData, [name]: value });
        }
    };


    const handleSignInSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setState({ ...state, loading: true });
        const BaseUrl = process.env.REACT_APP_API_URL;
        const data = {
            email: signInData.email,
            password: signInData.password,
        }
        const response = fetch(BaseUrl + '/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then((res) => res.json()).then((data) => {
            if (data.access_token) {
                toast.success('Giriş yapıldı', {
                    position: 'top-center',
                    autoClose: 2000, //3 seconds
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                localStorage.setItem('token', data.access_token);
                localStorage.setItem('refresh_token', data.refresh_token);
                navigate('/account');
            } else {
                console.error('error', data.message);
                toast.error(data.message, {
                    position: 'top-center',
                    autoClose: 2000, //3 seconds
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
            setState({ ...state, loading: false });
        }).catch((error) => {
            console.error('Error:', error);
            setState({ ...state, loading: false });

        })

    };


    const handleOtpForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setState({ ...state, loading: true });
        const BaseUrl = process.env.REACT_APP_API_URL;
        const response = fetch(BaseUrl + '/auth/verify-mail/' + userId + '/' + otpData.otp + '', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => res.json()).then((data) => {

            if (data.access_token) {
                toast.success('Mail dogrulandi', {
                    position: 'top-center',
                    autoClose: 3000, //3 seconds
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                localStorage.setItem('token', data.access_token);
                localStorage.setItem('refresh_token', data.refresh_token);
                navigate('/account');
            }
            setState({ ...state, loading: false });

        }).catch((error) => {
            console.error('Error:', error);
            setState({ ...state, loading: false });

        })
    }

    const handleSignUpSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setState({ ...state, loading: true });
        const data = {
            email: signUpData.email,
            password: signUpData.password,
            phoneNumber: signUpData.phone.replace(/[^\d+]/g, '')
        }
        const BaseUrl = process.env.REACT_APP_API_URL;
        const response = fetch(BaseUrl + '/user/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then((res) => res.json()).then((data) => {
            if (data.id) {
                setFormType('otp');
                setUserId(data.id);

            } else {
                console.error('error', data.message);
            }
            setState({ ...state, loading: false });

        }).catch((error) => {
            console.error('Error:', error);
            setState({ ...state, loading: false });
        })
    };

    return (
        <Layout>
            <div className="flex flex-col justify-center items-center h-screen">
                <div className="bg-white p-4 rounded-lg mt-[-20vh]">
                    {
                        formType !== 'otp' ? (
                            <div>
                                <h1 className="text-2xl font-bold mb-4">MZN Ortak Amazon Yazılımları'na Hoş Geldin! 👋🏻</h1>

                            </div>

                        ) : (
                            <div className='flex p-4 items-center justify-center'>
                                <span>Mail Doğrulama Kodunu Giriniz</span>
                            </div>
                        )
                    }
                    {formType === 'signin' ? (
                        <div>
                            <form onSubmit={handleSignInSubmit} className="flex flex-col gap-4">
                                <input
                                    type="text"
                                    name="email"
                                    placeholder="Email"
                                    value={signInData.email}
                                    onChange={(e: any) => handleInputChange(e, 'signin')}
                                    className="border border-gray-300 rounded-md py-2 px-4 "
                                    style={{ borderRadius: '10px' }}
                                />
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Parola"
                                    value={signInData.password}
                                    style={{ borderRadius: '10px' }}
                                    onChange={(e) => handleInputChange(e, 'signin')}
                                    className="border border-gray-300 rounded-md py-2 px-4"
                                />
                                <Button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-md w-full mt-4" style={{ borderRadius: '10px' }}>
                                    {state.loading ? <Loader className="h-7 w-7" /> : <span>Giriş</span>}
                                </Button>
                            </form>


                            <div className='flex justify-between items-center mt-4'>
                                <div className='text-center items-center justify-center'>
                                    <p>Hesabın yok mu ?</p>
                                </div>
                                <div>
                                    <button
                                        className={`py-2 px-4  bg-blue-500 text-white`}
                                        style={{ borderRadius: '10px' }}
                                        onClick={() => handleTabClick('signup')}
                                    >
                                        Kaydol
                                    </button>

                                </div>
                            </div>
                        </div>
                    ) : (
                        formType !== 'signup' ? (
                            <form onSubmit={handleOtpForm} className="flex flex-col gap-4">
                                <input
                                    type="text"
                                    name="otp"
                                    placeholder="Onay Kodu"
                                    value={otpData.otp}
                                    style={{ borderRadius: '10px' }}
                                    onChange={(e) => handleInputChange(e, 'otp')}
                                    className="border border-gray-300 rounded-md py-2 px-4"
                                />
                                <Button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-md">
                                    Onayla
                                </Button>
                            </form>
                        ) : (
                            <div>
                                <form onSubmit={handleSignUpSubmit} className="flex flex-col gap-4">

                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        style={{ borderRadius: '10px' }}
                                        value={signUpData.email}
                                        onChange={(e) => handleInputChange(e, 'signup')}
                                        className="border border-gray-300 rounded-md py-2 px-4"
                                    />
                                    <InputMask
                                        mask="+90 (999) 999 9999"
                                        type="text"
                                        name="phone"
                                        placeholder="Telefon"
                                        style={{ borderRadius: '10px' }}
                                        value={signUpData.phone}
                                        onChange={(e: any) => handleInputChange(e, 'signup')}
                                        className="border border-gray-300 rounded-md py-2 px-4"
                                    />
                                    <input
                                        type="password"
                                        name="password"
                                        style={{ borderRadius: '10px' }}
                                        placeholder="Parola"
                                        value={signUpData.password}
                                        onChange={(e) => handleInputChange(e, 'signup')}
                                        className="border border-gray-300 rounded-md py-2 px-4"
                                    />
                                    <Button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-md">
                                        {state.loading ? <Loader className="h-7 w-7" /> : <span>Sign Up</span>}
                                    </Button>
                                </form>
                                <div className='flex justify-between items-center mt-4'>
                                    <div className='text-center items-center justify-center'>
                                        <p>Zaten Hesabın Var Mı ?</p>
                                    </div>
                                    <div className="flex justify-between mb-4">
                                        <button
                                            className={`py-2 px-4 rounded-full bg-blue-500 text-white`}
                                            onClick={() => handleTabClick('signin')}
                                            style={{ borderRadius: '10px' }}
                                        >
                                            Giriş Yap
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )

                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Signin;
