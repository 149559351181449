import React, {
    createContext,
    useContext,
    useState
} from 'react';

const ThemeContext = createContext({} as any);

export const ThemeProvider = ({ children }: any) => {
    const [theme, setTheme] = useState('light');

    const toggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
    };

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);