import { Button } from '../ui/button';
const DownloadExtensionButton = () => {
    const handleDownload = () => {
        const zipFileUrl = 'mzn_extansion.zip';
        const link = document.createElement('a');
        link.href = zipFileUrl;
        link.download = 'mznExtansion.zip';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Button onClick={handleDownload} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
            Extension ZIP'i İndir
        </Button>
    );
};

export default DownloadExtensionButton;
