import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from './components/ui/themeProvider';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Signin from './pages/Signin';
import Account from './pages/Account';
import Homepage from './pages/Homepage';
import Payment from './pages/Payment';
import Success from './pages/Succes';
import Failed from './pages/Failed';
import { ToastContainer } from 'react-toastify';
import StickyButtons from './components/pages/stickButton';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ThemeProvider>
    <StickyButtons />
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Signin />} />
          <Route path="/account" element={<Account />} />
          <Route path="/homepage" element={<Homepage />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/success-payment" element={<Success />} />
          <Route path="/failed-payment" element={<Failed />} />
          <Route path="*" element={<Homepage />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </React.StrictMode>
  </ThemeProvider >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
