import * as React from "react";
const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M4 18L20 18"
            stroke="white"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M4 12L20 12"
            stroke="white"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M4 6L20 6"
            stroke="white"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </svg>
);
export default SVGComponent;
