import Layout from "../../components/layout"

export default function Component() {
    return (
        <Layout title="Failure">
            <div className="flex flex-col min-h-screen">
                <main className="flex flex-col items-center justify-center flex-grow text-center p-4 md:p-6">
                    <CrossCircleIcon className="h-16 w-16 text-red-500" />
                    <h1 className="mt-4 text-2xl font-semibold">Ödeme Başarısız</h1>
                    <p className="mt-2 text-gray-500 dark:text-gray-400">Ödeme işleminiz gerçekleştirilemedi. Lütfen tekrar deneyin.</p>
                    {/* <div className="mt-6 border rounded-lg p-4 w-full max-w-md">
                    <div className="flex justify-between text-sm">
                        <span>Amount Attempted:</span>
                        <span className="font-medium">$100.00</span>
                    </div>
                    <div className="flex justify-between text-sm mt-2">
                        <span>Date & Time:</span>
                        <span className="font-medium">January 22, 2024, 10:30 AM</span>
                    </div>
                    <div className="flex justify-between text-sm mt-2">
                        <span>Reference Number:</span>
                        <span className="font-medium">1234567890</span>
                    </div>
                </div> */}
                    <a
                        className="mt-6 inline-flex items-center justify-center h-10 px-4 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        href="/account"
                    >
                        Anasayfaya Dön
                    </a>
                </main>
                <footer className="flex items-center justify-center h-14 border-t">
                    <p className="text-sm text-gray-500 dark:text-gray-400">© 2024 Mzn Danışmanlık.</p>
                </footer>
            </div>
        </Layout>
    )
}

function CrossCircleIcon({ ...props }) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="12" cy="12" r="10" />
            <line x1="15" y1="9" x2="9" y2="15" />
            <line x1="9" y1="9" x2="15" y2="15" />
        </svg>
    )
}
