import { ExitFullScreenIcon, ExitIcon, MagnifyingGlassIcon, PersonIcon } from "@radix-ui/react-icons";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../ui/button";

export default function Header({ title }: any) {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    return (
        <div className="w-100 h-[100px] flex items-center justify-between p-5 bg-black shadow-lg">

            <div className="block sm:flex">
                <Link to={"https://mzndanismanlik.com/"}>
                    <div className="justify-center items-center flex">
                        <img src="https://mzndanismanlik.com/wp-content/uploads/2024/04/logo.png.webp" alt="logo" height={100} width={100} />
                    </div>
                </Link>

                <Link to={"/"}>
                    <div className="justify-center items-center flex ml-4">
                        <div onClick={() => navigate('')}>
                            <span className="text-primary text-zinc-50 font-poppins text-lg md:text-3xl font-bold">MZN Ortak Yazılımlar</span>
                        </div>
                    </div>
                </Link>
            </div>
            {/* <span>deneme</span> */}

            {
                title && (
                    token ? (
                        <div className="flex">
                            <div>
                                <Link to="/account">
                                    <Button>
                                        <PersonIcon className="w-7 h-7" color="white" />
                                    </Button>
                                </Link>
                            </div>
                            <div className="ml-4">
                                <Button onClick={() => (
                                    localStorage.removeItem('token'),
                                    localStorage.removeItem('refresh_token'),
                                    navigate('/login')
                                )}>
                                    <ExitIcon className="w-7 h-7" color="white" />
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className="flex">
                            <div >
                                <Link to="/login">
                                    <Button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4" style={{ borderRadius: '10px' }}>Giriş Yap</Button>
                                </Link>
                            </div>
                        </div>
                    )
                )
            }

        </div >
    );
}
