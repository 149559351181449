import * as React from "react";
const SVGComponent = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5031 3.48755C18.2473 1.23969 15.2471 0.00114047 12.0504 0C5.4634 0 0.102564 5.33454 0.100272 11.8917C0.099126 13.9878 0.649764 16.0338 1.69546 17.8369L0 24L6.33491 22.3463C8.0802 23.2941 10.0455 23.793 12.0453 23.7936H12.0504C18.6363 23.7936 23.9977 18.4585 24 11.9013C24.0011 8.72347 22.7595 5.73599 20.5031 3.48812V3.48755ZM12.0504 21.7852H12.0464C10.2644 21.7847 8.51627 21.3079 6.991 20.4075L6.62828 20.1932L2.86893 21.1745L3.87223 17.5267L3.63615 17.1526C2.64203 15.5788 2.1166 13.7597 2.11775 11.8923C2.12004 6.44251 6.57556 2.00836 12.0545 2.00836C14.7073 2.0095 17.201 3.03878 19.0763 4.90743C20.9517 6.77553 21.9837 9.25948 21.9825 11.9002C21.9802 17.3505 17.5247 21.7847 12.0504 21.7847V21.7852ZM17.4983 14.3824C17.1998 14.2336 15.7319 13.5151 15.4579 13.4159C15.1841 13.3167 14.9853 13.2671 14.7864 13.5647C14.5876 13.8624 14.0152 14.5319 13.841 14.7297C13.6668 14.9281 13.4926 14.9527 13.1941 14.8038C12.8956 14.655 11.9335 14.3414 10.7927 13.3292C9.9052 12.5411 9.3058 11.5683 9.13167 11.2706C8.95747 10.973 9.11333 10.8122 9.26227 10.6645C9.39633 10.5311 9.5608 10.3172 9.71033 10.1439C9.85993 9.97052 9.9092 9.84625 10.0089 9.64834C10.1086 9.4499 10.0587 9.2766 9.98427 9.12772C9.90973 8.9789 9.31273 7.51622 9.06347 6.92149C8.82107 6.34214 8.57473 6.42084 8.39193 6.41114C8.21773 6.40259 8.01893 6.40088 7.81953 6.40088C7.62013 6.40088 7.29693 6.47501 7.02307 6.77267C6.7492 7.07031 5.97794 7.78937 5.97794 9.25145C5.97794 10.7135 7.04773 12.1272 7.19727 12.3256C7.3468 12.5241 9.30293 15.5252 12.2979 16.8128C13.0102 17.119 13.5665 17.302 14.0003 17.4389C14.7154 17.6653 15.3663 17.6334 15.8808 17.5569C16.4544 17.4714 17.6473 16.8379 17.896 16.1439C18.1447 15.4499 18.1447 14.8546 18.0702 14.7308C17.9957 14.6071 17.7963 14.5324 17.4978 14.3836L17.4983 14.3824Z"
      fill="#fff"
    />
  </svg>
);
export default SVGComponent;
