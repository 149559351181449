import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../pages/slider.css'; // Stil dosyamızı daha sonra ekleyeceğiz
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../../components/ui/dialog';
import { Button } from '../ui/button';


const MySlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 768, // 768px ve altı için
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const images = [
        `https://mznamazonyazilimlari.com/test/wp-content/uploads/2024/06/MZN-2-scaled-1.webp`,
        `https://mznamazonyazilimlari.com/test/wp-content/uploads/2024/06/MZN-5-scaled-1.webp`,
        `https://mznamazonyazilimlari.com/test/wp-content/uploads/2024/06/MZN-6-scaled-1.webp`,
        `https://mznamazonyazilimlari.com/test/wp-content/uploads/2024/06/MZN-7-scaled-1.webp`,
        `https://mznamazonyazilimlari.com/test/wp-content/uploads/2024/06/MZN-8-scaled-1.webp`,
        `https://mznamazonyazilimlari.com/test/wp-content/uploads/2024/06/MZN-11-scaled-2.webp`,
        `https://mznamazonyazilimlari.com/test/wp-content/uploads/2024/06/MZN-12-scaled-1.webp`,
        `https://mznamazonyazilimlari.com/test/wp-content/uploads/2024/06/MZN-13-scaled-1.webp`,
        `https://mznamazonyazilimlari.com/test/wp-content/uploads/2024/06/MZN-2-scaled-1.webp`,
    ];
    const [open, setIsOpen] = React.useState(false);

    const [selectedPackets, setSelectedPackets] = React.useState('');

    return (
        <div className="slider-container">
            <div className='flex justify-center items-center p-4'>
                <div>
                    <span className='text-5xl font-bold'>Merkez Ofisimizden Kareler</span>
                </div>
            </div>
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index} className="slider-item" onClick={() => {
                        setIsOpen(true)
                        setSelectedPackets(image)
                    }}>
                        <img src={image} alt={`Slide ${index}`} />
                    </div>
                ))}
            </Slider>
            <Dialog open={open} onOpenChange={setIsOpen}>
                <DialogContent className="sm:max-w-[625px] bg-white" style={{ borderRadius: '10px' }}>
                    <img src={selectedPackets} alt="" />

                </DialogContent>
            </Dialog>
        </div>
    );
};

export default MySlider;
